/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

const packageSlice = createSlice({
    name:'packages',
    initialState:{
        packages:[],
    },
    reducers:{
        setPackages:(state,action)=>{
            state.packages = action.payload
        },
    }

})

export const {setPackages} = packageSlice.actions

export default packageSlice.reducer