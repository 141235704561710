import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// slices
import eventSlice from './eventSlice'
import packageSlice from './packageSlice'
import salesPerson from './salesPerson'
import authSlice from './authSlice'
import products from './productSlice'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  // whitelist: [],
}
const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  // whitelist: [],
}

const rootReducer = combineReducers({
  auth: authSlice,
  events: eventSlice,
  packages: packageSlice,
  salesPerson: salesPerson,
  products:persistReducer(productPersistConfig,products),
})

export { rootPersistConfig, rootReducer }
