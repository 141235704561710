import React, { Suspense , useState , useEffect } from 'react'
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom'
import { CContainer, CSpinner } from '@coreui/react'
import { profileService } from 'src/services/profile'
// routes config
import routes from '../routes'

const NotFoundPage = () => {
  // Redirect to a specific page if route not found
  return <Navigate to="/404" />
}

const AppContent = () => {
  const navigate = useNavigate()
  const exp = localStorage.getItem('accessTokenExpiry');

  const [user, setUser] = useState(null)
  const getProfile = () => {
    profileService
      .getProfile()
      .then((data) => {
        // console.log(data,'user___________')
        setUser(data.data)
      })
      .catch((err) => {
        console.log(err.message)
      })
  }
  useEffect(() => {
    getProfile()
  }, [])

  console.log(user?.roleId, "ROleid");


  if (!exp) {
    localStorage.clear()
    navigate('/login')
  } else {
    return (
      <CContainer fluid className=''>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            {routes.map((route, idx) => {
              return (
                route.element && (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    element={<route.element />}
                  />
                )
              )
            })}
            {user && user?.roleId === 1 ? (
              <Route path="/" element={<Navigate to="/dashboard-main" />} />
            ) : (
              <Route path="/" element={<Navigate to="/quotes" />} />
            )}

            <Route path="*" element={<NotFoundPage />} /> {/* Wildcard route */}
          </Routes>
        </Suspense>
      </CContainer>
    )
  }
}

export default React.memo(AppContent)
