import React from 'react'
import { CFooter } from '@coreui/react'

const AppFooter = () => {
  return (
    <CFooter className="bg-info w-100 position-fixed bottom-0 left-0">
      <div>
        <span className="ms-1 text-white">&copy; 2024 </span>
      </div>
      <div className="ms-auto text-white">
        <span className="me-1">Powered by</span>
        <a
          href="https://subcodevs.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary"
        >
          Subcodevs
        </a>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
