/* eslint-disable prettier/prettier */
import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { rootPersistConfig, rootReducer } from '../slices/rootReducer'



const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),

  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})

let persistor = persistStore(store)

export {store,persistor}