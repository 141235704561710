import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import AuthGuard from './guard/AuthGuard'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'react-toastify/dist/ReactToastify.css'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const PaymentsPage = React.lazy(() => import('./views/pages/Payments/PaymentsPage'))
const PaymentSuccess = React.lazy(() => import('./views/pages/Payments/PaymentSuccess'))
const PaymentCancel = React.lazy(() => import('./views/pages/Payments/PaymentCancel'))
const ForgotPassword = React.lazy(() => import('./views/pages/forgotpassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/pages/forgotpassword/ResetPassword'));
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidToken: true
    };
  }

  componentDidMount() {
    this.validateToken();
  }

  validateToken() {
    const accessTokenExpiry = localStorage.getItem('accessTokenExpiry');
    if (!accessTokenExpiry) {
      this.setState({ isValidToken: false });
    } else {
      const currentTime = Date.now() / 1000;
      if (Number(accessTokenExpiry) > currentTime) {
        this.setState({ isValidToken: true });
      } else {
        this.setState({ isValidToken: false });
      }
    }
  }

  render() {

    console.log(this.state.isValidToken, "tokenValid_____________");


    return (
      <>
        <BrowserRouter>
          <ToastContainer />
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/login" name="Login Page" element={this.state.isValidToken ? <Navigate to="/" /> : <Login />}/>
              <Route exact path="/" name="Dashboard" element={<AuthGuard />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/payments/:id" name="Payment Page" element={<PaymentsPage />} />
              <Route
                exact
                path="/payments/success/:id"
                name="Payment Page"
                element={<PaymentSuccess />}
              />

              <Route
                  exact
                  path="/auth/:pageId/:token"
                  name="Resert Password"
                  element={<ResetPassword />}
                />


              <Route
                exact
                path="/payments/cancel"
                name="Payment Page"
                element={<PaymentCancel />}
              />
              <Route exact path="/forgotpassword" name="ForgotPassword" element={<ForgotPassword />} />

              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route exact path="*" name="Home" element={<AuthGuard />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </>
    )
  }
}

export default App
