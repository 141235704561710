/* eslint-disable prettier/prettier */
import React from 'react'
import { AppContent, AppHeader } from '../components/index'
import { useNavigate } from 'react-router-dom'

// eslint-disable-next-line react/prop-types
const DefaultLayout = ({isValidToken}) => {
  const navigate = useNavigate()
  const exp = localStorage.getItem('accessTokenExpiry')
  if(!isValidToken){
    exp && alert('Token Expired')
    localStorage.clear()
    navigate('/login')
  }
  return(
    <div  className='overflow-hidden'>
      {/* <AppSidebar /> */}
      <div className="wrapper d-flex flex-column min-vh-100 bg-light position-relative">
        <AppHeader />
        <div className="body flex-grow-1 px-3 " style={{marginTop:'100px'}}>
          <AppContent />
        </div>
        {/* <AppFooter /> */}
      </div>
    </div>
  )
}

export default DefaultLayout
