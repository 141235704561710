/* eslint-disable prettier/prettier */
import React from 'react'

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'))
const Colors = React.lazy(() => import('./views/theme/colors/Colors'))
const Typography = React.lazy(() => import('./views/theme/typography/Typography'))

// Base
const Accordion = React.lazy(() => import('./views/base/accordion/Accordion'))
const Breadcrumbs = React.lazy(() => import('./views/base/breadcrumbs/Breadcrumbs'))
const Cards = React.lazy(() => import('./views/base/cards/Cards'))
const Carousels = React.lazy(() => import('./views/base/carousels/Carousels'))
const Collapses = React.lazy(() => import('./views/base/collapses/Collapses'))
const ListGroups = React.lazy(() => import('./views/base/list-groups/ListGroups'))
const Navs = React.lazy(() => import('./views/base/navs/Navs'))
const Paginations = React.lazy(() => import('./views/base/paginations/Paginations'))
const Placeholders = React.lazy(() => import('./views/base/placeholders/Placeholders'))
const Popovers = React.lazy(() => import('./views/base/popovers/Popovers'))
const Progress = React.lazy(() => import('./views/base/progress/Progress'))
const Spinners = React.lazy(() => import('./views/base/spinners/Spinners'))
const Tables = React.lazy(() => import('./views/base/tables/Tables'))
const Tooltips = React.lazy(() => import('./views/base/tooltips/Tooltips'))

// Buttons
const Buttons = React.lazy(() => import('./views/buttons/buttons/Buttons'))
const ButtonGroups = React.lazy(() => import('./views/buttons/button-groups/ButtonGroups'))
const Dropdowns = React.lazy(() => import('./views/buttons/dropdowns/Dropdowns'))

//Forms
const ChecksRadios = React.lazy(() => import('./views/forms/checks-radios/ChecksRadios'))
const FloatingLabels = React.lazy(() => import('./views/forms/floating-labels/FloatingLabels'))
const FormControl = React.lazy(() => import('./views/forms/form-control/FormControl'))
const InputGroup = React.lazy(() => import('./views/forms/input-group/InputGroup'))
const Layout = React.lazy(() => import('./views/forms/layout/Layout'))
const Range = React.lazy(() => import('./views/forms/range/Range'))
const Select = React.lazy(() => import('./views/forms/select/Select'))
const Validation = React.lazy(() => import('./views/forms/validation/Validation'))

const Charts = React.lazy(() => import('./views/charts/Charts'))

// Icons
const CoreUIIcons = React.lazy(() => import('./views/icons/coreui-icons/CoreUIIcons'))
const Flags = React.lazy(() => import('./views/icons/flags/Flags'))
const Brands = React.lazy(() => import('./views/icons/brands/Brands'))

// Notifications
const Alerts = React.lazy(() => import('./views/notifications/alerts/Alerts'))
const Badges = React.lazy(() => import('./views/notifications/badges/Badges'))
const Modals = React.lazy(() => import('./views/notifications/modals/Modals'))
const Toasts = React.lazy(() => import('./views/notifications/toasts/Toasts'))
const Widgets = React.lazy(() => import('./views/widgets/Widgets'))

// Pages

// Quote
const QuoteAdd = React.lazy(() => import('./views/pages/quote/QuoteAdd'));
const QuoteList = React.lazy(() => import('./views/pages/quote/QuoteList'));
const QuoteEdit = React.lazy(() => import('./views/pages/quote/QuoteEdit'));

// Quote End 

// Event 
const EventAdd =  React.lazy(() => import('./views/pages/Events/EventAdd'));
const EventEdit = React.lazy(() => import('./views/pages/Events/EventEdit'));
// end

// Packages
const AddPackages = React.lazy(() => import('./views/pages/packages/AddPackages'));
const EditPackages = React.lazy(() => import('./views/pages/packages/EditPackages'));
const SettingPage = React.lazy(() => import('./views/pages/Setting/SettingPage'));
const SalesPersonAdd = React.lazy(() => import('./views/pages/salesperson/SalesPersonAdd'));
const SalesPersonEdit = React.lazy(() => import('./views/pages/salesperson/SalesPersonEdit'));

// Accounts
const StripeSetting = React.lazy(() => import('./components/accounts/StripeSettings'));
const Profile = React.lazy(() => import('./components/accounts/Profile'));
const changePassword = React.lazy(() => import('./views/pages/changepassword/changePassword'));

// Dasboard 
const DashBoardPage = React.lazy(() => import('./views/pages/dashboard/DashBoardPage'));

// Organisations 
const OrganisationPage = React.lazy(() => import('./views/pages/organisation/OrganisationPage'));

const OrganisationViewPage = React.lazy(() => import('./views/pages/organisation/OrganisationViewPage'));

const settingOrg = React.lazy(() => import('./views/pages/organisation/settingOrg'));

const QotesComponentsOrg = React.lazy(() => import('./views/pages/organisation/OrganisationViewPage'));

const routes = [
  { path: '/', exact: true, name: 'Login Page' },
  { path: '/dashboard', name: 'Dashboard', element: Dashboard },
  { path: '/theme', name: 'Theme', element: Colors, exact: true },
  { path: '/theme/colors', name: 'Colors', element: Colors },
  { path: '/theme/typography', name: 'Typography', element: Typography },

  { path: '/quotes/add', name: 'Add Quote', element: QuoteAdd },
  { path: '/quotes/view/:id', name: 'View Quote', element: QuoteEdit },
  { path: '/quotes/edit/:id', name: 'Edit Quote', element: QuoteAdd },
  { path: '/quotes', name: 'Quotes', element: QuoteList },
 
  { path: '/event/add', name: 'Add Event', element: EventAdd },
  { path: '/event/edit/:id', name: 'Edit Event', element: EventEdit },

  { path: '/packages/add', name: 'Packages', element: AddPackages },
  { path: '/packages/edit/:id', name: 'Edit Packages', element: EditPackages},

  // Sales Person
  { path: '/saleperson/add', name: 'Sale Person Add', element: SalesPersonAdd },
  { path: '/saleperson/edit/:id', name: 'Sale Person Edit', element: SalesPersonEdit },

  // Accounts
  { path: '/profile', name: 'Profile', element: Profile },
  { path: '/stripeSetting', name: 'Stripe Settings', element: StripeSetting },
  
  // Change Password
  { path: '/changepassword', name: 'Change Password', element: changePassword },

  // Setting 
  { path: '/settings', name: 'Settings', element: SettingPage },


  // Dashboard

  { path: '/dashboard-main', name: 'Dashboard', element: DashBoardPage },

  // Organisation

  { path: '/organisation', name: 'Organisation', element: OrganisationPage },

  { path: '/organisation/view/:id', name: 'Organisation View', element: settingOrg },

  { path: '/organisation/quote/view/:id', name: 'Organisation View', element: QotesComponentsOrg },



  { path: '/base', name: 'Base', element: Cards, exact: true },
  { path: '/base/accordion', name: 'Accordion', element: Accordion },
  { path: '/base/breadcrumbs', name: 'Breadcrumbs', element: Breadcrumbs },
  { path: '/base/cards', name: 'Cards', element: Cards },
  { path: '/base/carousels', name: 'Carousel', element: Carousels },
  { path: '/base/collapses', name: 'Collapse', element: Collapses },
  { path: '/base/list-groups', name: 'List Groups', element: ListGroups },
  { path: '/base/navs', name: 'Navs', element: Navs },
  { path: '/base/paginations', name: 'Paginations', element: Paginations },
  { path: '/base/placeholders', name: 'Placeholders', element: Placeholders },
  { path: '/base/popovers', name: 'Popovers', element: Popovers },
  { path: '/base/progress', name: 'Progress', element: Progress },
  { path: '/base/spinners', name: 'Spinners', element: Spinners },
  { path: '/base/tables', name: 'Tables', element: Tables },
  { path: '/base/tooltips', name: 'Tooltips', element: Tooltips },
  { path: '/buttons', name: 'Buttons', element: Buttons, exact: true },
  { path: '/buttons/buttons', name: 'Buttons', element: Buttons },
  { path: '/buttons/dropdowns', name: 'Dropdowns', element: Dropdowns },
  { path: '/buttons/button-groups', name: 'Button Groups', element: ButtonGroups },
  { path: '/charts', name: 'Charts', element: Charts },
  { path: '/forms', name: 'Forms', element: FormControl, exact: true },
  { path: '/forms/form-control', name: 'Form Control', element: FormControl },
  { path: '/forms/select', name: 'Select', element: Select },
  { path: '/forms/checks-radios', name: 'Checks & Radios', element: ChecksRadios },
  { path: '/forms/range', name: 'Range', element: Range },
  { path: '/forms/input-group', name: 'Input Group', element: InputGroup },
  { path: '/forms/floating-labels', name: 'Floating Labels', element: FloatingLabels },
  { path: '/forms/layout', name: 'Layout', element: Layout },
  { path: '/forms/validation', name: 'Validation', element: Validation },
  { path: '/icons', exact: true, name: 'Icons', element: CoreUIIcons },
  { path: '/icons/coreui-icons', name: 'CoreUI Icons', element: CoreUIIcons },
  { path: '/icons/flags', name: 'Flags', element: Flags },
  { path: '/icons/brands', name: 'Brands', element: Brands },
  { path: '/notifications', name: 'Notifications', element: Alerts, exact: true },
  { path: '/notifications/alerts', name: 'Alerts', element: Alerts },
  { path: '/notifications/badges', name: 'Badges', element: Badges },
  { path: '/notifications/modals', name: 'Modals', element: Modals },
  { path: '/notifications/toasts', name: 'Toasts', element: Toasts },
  { path: '/widgets', name: 'Widgets', element: Widgets },
]

export default routes
