/* eslint-disable prettier/prettier */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import { AppSidebarNav } from './AppSidebarNav'

import { logoNegative } from 'src/assets/brand/logo-negative'
import { sygnet } from 'src/assets/brand/sygnet'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import navigation from '../_nav'
import logoImg from './../assets/images/logoImg.png'
import axios from 'axios'
import { cilAccountLogout } from '@coreui/icons'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)

  const { user } = useSelector((state) => state.auth)
  const filteredNav = navigation.filter((nav) => nav.roleId !== user.roleId)

  const logout = () => {
    localStorage.clear()
    axios.defaults.headers.common['Authorization'] = ''
  }

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/quote">
        {/* <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} /> */}
        {/* <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} /> */}
        <a href="/quotes" className="LogImgShow">
          <img src={logoImg} alt="logoImg" />
        </a>
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={filteredNav} />
        </SimpleBar>
      </CSidebarNav>
      
      
        <a href="/login" className="d-flex align-items-baseline LogOutClass px-4 py-2 border-1" onClick={() => logout()}>
          <div style={{height:'20px',width:'20px',marginRight:24,}}>

            <CIcon icon={cilAccountLogout} customClassName="nav-icon" style={{color:'white'}}/>
          </div>
           Logout
        </a>

      {/* <CSidebarToggler
        className="d-none d-lg-flex customStyle"
      >
        <a href='/login' className='LogOutClass' onClick={()=>logout()}> Logout </a> 
      </CSidebarToggler> */}
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
