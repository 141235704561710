/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name:'auth',
    initialState:{
        user:{},
        accessToken:'',
        refreshToken:''
    },
    reducers:{
        setUser:(state,action)=>{
            state.user = action.payload
        },
        setAccessToken:(state,action)=>{
            state.accessToken = action.payload
        },
        setRefreshToken:(state,action)=>{
            state.refreshToken = action.payload
        }
    }

})

export const {setAccessToken, setRefreshToken, setUser} = authSlice.actions

export default authSlice.reducer