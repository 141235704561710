import React, { useEffect } from 'react'
import { CContainer, CHeader } from '@coreui/react'

import { AppBreadcrumb } from './index'
import { profileService } from 'src/services/profile'
import axios from 'axios'
import {  useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
const AppHeader = () => {
  const [user, setUser] = React.useState()
  const navigate = useNavigate()
  const getProfile = () => {
    profileService
      .getProfile()
      .then((data) => {
        // console.log(data,'user___________')
        setUser(data.data)
      })
      .catch((err) => {
        console.log(err, "Profile");
      
        if (err?.response?.data?.userLogout) {
          console.log("User logout detected");
          toast.error(err?.response?.data?.message);
          localStorage.clear();
          axios.defaults.headers.common['Authorization'] = '';
          console.log("Redirecting to login page...");
          window.location.href = '/login';
          // Make sure navigate is defined and working properly
          // navigate('/login');
        } else {
          console.log("Other error occurred, handle as needed");
          // Handle other types of errors here
        }

      })
  }
  useEffect(() => {
    getProfile()
  }, [])
  return (
    <CHeader className=" bg-white w-100 position-fixed top-0 z-indexData">
      <CContainer fluid>{user && <AppBreadcrumb user={user} />}</CContainer>
    </CHeader>
  )
}

export default AppHeader
