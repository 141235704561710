/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import DefaultLayout from 'src/layout/DefaultLayout'

function AuthGuard() {

    const accessTokenExpiry = localStorage.getItem('accessTokenExpiry')
    const [isValidToken,setIsValidToken] = useState(true)
    const validateToken = () => {
      if (!accessTokenExpiry) {
        setIsValidToken(false)
      } else {
        const currentTime = Date.now()/1000
        console.log({currentTime,accessTokenExpiry : Number(accessTokenExpiry)})
  
        if (Number(accessTokenExpiry)  > currentTime) {
          setIsValidToken(true)
        } else {
          setIsValidToken(true)
        }
      }
      // console.log(isValidToken,"tokenValid")
    }
    useEffect(()=>{
      validateToken()
    },[])
  
  return (
    <>
     <DefaultLayout isValidToken={isValidToken}/> 
    </>
  )
}

export default AuthGuard
