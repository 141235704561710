/* eslint-disable prettier/prettier */
import axios from '../axios'
import { config } from './config'

const getProfile = async () => {
  const {data} = await axios.get('api/v1/user/profile', config)
  return data
}

const getStripeKeys = async () => {
  const data = await axios.get('api/v1/user/get_stripe_keys', config)
  return data.data
}

const updateStripeKeys = async (body) => {
  const data = await axios.put(`api/v1/user/update_stripe_keys`, body, config)
  return data.data
}

const updateProfile = async (bodyProfileData) => {
  const data = await axios.post(`api/v1/user/update-profile`, bodyProfileData, config)
  return data.data
}

export const profileService = {
    getProfile,
    getStripeKeys,
    updateStripeKeys,
    updateProfile
}
