import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBell,
  cilCalculator,
  cilChartPie,
  cilCursor,
  cilDescription,
  cilDrop,
  cilNotes,
  cilPencil,
  cilPuzzle,
  cilHappy,
  cilBadge,
  cilSpeedometer,
  cilMonitor,
  cilApplications,
  cilStar,
  cilAccountLogout,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'

const _nav = [
  {
    component: CNavItem,
    name: 'Quotes',
    to: '/quotes',
    roleId: 0,
    icon: <CIcon icon={cilDrop} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Settings',
    to: '/settings',
    roleId: 3,
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
  },

]

export default _nav
