/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

const productSlice = createSlice({
    name:'products',
    initialState:{
        products:[],
    },
    reducers:{
        setProducts:(state,action)=>{
            state.products = action.payload
        },
    }

})

export const {setProducts} = productSlice.actions

export default productSlice.reducer