/* eslint-disable react/prop-types */
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import routes from '../routes'

import { CBreadcrumb, CBreadcrumbItem } from '@coreui/react'
import logoimg from '../assets/images/logo_new.png'
import { Button } from '@coreui/coreui'
import { CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'
import CIcon from '@coreui/icons-react'

import axios from 'axios'
import { cilAccountLogout } from '@coreui/icons'
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { Logout } from '@mui/icons-material'
const AppBreadcrumb = ({ user }) => {
  const currentLocation = useLocation().pathname

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getRouteName = (pathname, routes) => {
    const currentRoute = routes.find((route) => route.path === pathname)
    return currentRoute ? currentRoute.name : false
  }

  const logout = () => {
    localStorage.clear()
    axios.defaults.headers.common['Authorization'] = ''
    window.location.href = '/login'
  }

  const getBreadcrumbs = (location) => {
    const breadcrumbs = []
    location.split('/').reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`
      const routeName = getRouteName(currentPathname, routes)
      routeName &&
        breadcrumbs.push({
          pathname: currentPathname,
          name: routeName,
          active: index + 1 === array.length ? true : false,
        })
      return currentPathname
    })
    return breadcrumbs
  }

  const breadcrumbs = getBreadcrumbs(currentLocation)

  return (
    <CBreadcrumb className="m-0 ms-2">
      <div className="d-flex w-100 justify-content-between align-items-center">
        <div className="LogoImg">
        
        {user?.roleId == 1 ? <> <a href="/organisation" className="LogImgShow">
            <img src={logoimg} alt="logoImg" />
          </a> </> : <> <a href="/quotes" className="LogImgShow">
            <img src={logoimg} alt="logoImg" />
          </a> </> }


        </div>

        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center', color: '#1172b7' }}>
          
          {/* <Typography sx={{ minWidth: 100, color: '#fff' }}>
            <NavLink className={'font-weight-bold'} to={'/dashboard-main'}> Dashboard </NavLink>
          </Typography>
         */}

        {user?.roleId == 1 && (  
            <Typography sx={{ minWidth: 100, color: '#1172b7' }}>
              <NavLink className={'font-weight-bold'} style={{color:'#1172b7' }} to={'/organisation'}> Organisations </NavLink>
            </Typography>
        )}

        {user?.roleId !== 1 && (  
          <Typography sx={{ minWidth: 100, color: '#1172b7' }}>
            <NavLink className={'font-weight-bold'} style={{color:'#1172b7' }} to={'/quotes'}> Quotes </NavLink>
          </Typography>
        )}
        {user?.roleId !== 1 && (
          <Typography sx={{ minWidth: 100 }}>
            <NavLink className={'font-weight-bold'} style={{color:'#1172b7' }} to={'/settings'}> Settings </NavLink>
          </Typography>
        )}
          <Tooltip title={user?.firstName} sx={{ color: '#1172b7' }}>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
            >
              <Avatar sx={{ width: 32, height: 32 }}></Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        > 

        {user?.roleId !== 1 && (
          <MenuItem onClick={handleClose}>
            <NavLink to={'/profile'} className="p-0 m-0 d-flex w-100" style={{ color: '#000' }}>
              {' '}
              Profile{' '}
            </NavLink>
          </MenuItem>
          )}
          {user?.roleId == 2 && (
            <MenuItem onClick={handleClose}>
              <NavLink to={'/stripeSetting'} className="p-0 m-0 d-flex w-100" style={{ color: '#000' }}>
                Stripe Settings
              </NavLink>
            </MenuItem>
          )}

          <MenuItem onClick={handleClose}>
            <NavLink to={'/changepassword'} className="p-0 m-0 d-flex w-100" style={{ color: '#000' }}>
              {' '}
              Change Password{' '}
            </NavLink>
          </MenuItem>

          <MenuItem onClick={handleClose}>
            <button
              // href="/login"
              className="p-0 m-0 d-flex w-100"
              onClick={() => logout()}
              style={{ color: '#000', border:0, backgroundColor:'transparent' }}
            >
              Logout
            </button>
          </MenuItem>
        </Menu>
      </div>
    </CBreadcrumb>
  )
}

export default React.memo(AppBreadcrumb)
