/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

const salesPersonSlice = createSlice({
    name:'salesPersons',
    initialState:{
        salePersons:[],
    },
    reducers:{
        setSalePersons:(state,action)=>{
            state.salePersons = action.payload
        },
    }

})

export const {setSalePersons} = salesPersonSlice.actions

export default salesPersonSlice.reducer